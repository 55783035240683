import { currentPage, initNewSection } from '../common/layout';
import { banners, commentsCountUpdate, hitPageView, bannerLazyload } from '../common/utils/fn';
import initLoadMoreBtn from '../common/load-more-btn';
import { getMakeModelFilter } from '../common/make-model-filter-loader';
import '../common/msnt/msnt-lightslider.js';
import '../common/jquery-snap-scroll';
import '../common/jquery.convertmosaic';
import '../common/jquery.sticky';
import { getM1FindVehicleEu, getM1FindVehicleUs } from '../common/shop/m1-find-vehicle-widget-loader';
import { getCoralCommentsCountUpdate } from '../common/utils/coral-requests-loader';

currentPage.pageInit = ($page, pageData, pageId) => {
  let sideBarCollection = '.sidebar';
  let currentEdition = document.querySelector('body').dataset.edtn;
  $(sideBarCollection).stickySidebar({ containerSelector: '.content-area' });
  $('#main_CR').convertMosaicToFilm();
  // fn.bigImgLazyload();
  initLoadMoreBtn();
  getMakeModelFilter().then((api) => {
    api.makeModelFilter();
  });
  getM1FindVehicleEu().then((api) => {
    api.m1FindVehicleEu();
  });
  getM1FindVehicleUs().then((api) => {
    api.m1FindVehicleUs();
  });
  getCoralCommentsCountUpdate().then((api) => {
    api.coralCommentsCountUpdate();
  });
  commentsCountUpdate();
};

currentPage.pageLoadMore = ($html, $btnBox) => {
  $btnBox.replaceWith($html);
  //bigImgLazyload();
  // hybridLazyLoad();
  getM1FindVehicleEu().then((api) => {
    api.m1FindVehicleEu();
  });
  getM1FindVehicleUs().then((api) => {
    api.m1FindVehicleUs();
  });
  initNewSection($html);
  $('.sidebar').stickySidebar({ containerSelector: '.content-area' });
  banners($html);
  commentsCountUpdate(true);
  hitPageView(document.location.pathname);
  bannerLazyload();
  getCoralCommentsCountUpdate().then((api) => {
    api.coralCommentsCountUpdate();
  });
};
